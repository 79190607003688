import { io } from 'socket.io-client'
import { getConfig } from './config'

const config = getConfig(process.env.REACT_APP_ENVIRONMENT)

let socket

const connect = (url) => {
    if (!socket) {
        socket = io(url || config.baseAPIUrl, {
            reconnectionDelayMax: 60000
        })
    }
    return socket
}

export default connect
export const getConfig = (env) => {
    console.log('getting config for', env)
    switch (env?.toUpperCase()) {
        case 'DEV':
            return {
                baseAPIUrl: 'https://planning-poker-dev.herokuapp.com'
            }
        case 'PRODUCTION':
            return {
                baseAPIUrl: 'https://planning-poker-production.herokuapp.com'
            }
        default:
            return {
                baseAPIUrl: 'http://localhost:3000'
            }
    }
}
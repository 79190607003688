import React from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import RefreshIcon from '@mui/icons-material/Refresh'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Visibility from '@mui/icons-material/Visibility'
import Checkbox from '@mui/material/Checkbox'


const VoteButton = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '80px',
}))

const gray = '#dedede'
const green = '#99e0ac'

const fibonacci = [0, 0.5, 1, 2, 3, 5, 8, 13, 21, 34, 100]

function VoterDisplay({ members = [], resetRoom, member, votesAreDisplayed = false, toggleVotesAreDisplayed }) {
    const [voters, setVoters] = useState([])
    const [votersThatHaveVoted, setVotersThatHaveVoted] = useState([])
    const [voterAverage, setVoterAverage] = useState(0)
    const [closestEstimate, setClosestEstimate] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        setVoters(members
            .filter(member => member.isAVoter))
    }, [members])

    useEffect(() => {
        const votingVoters = voters
            .filter(voter => voter.vote !== undefined)
        setVotersThatHaveVoted(votingVoters)
        const sumOfAllVotes = votingVoters
            .map(voter => parseInt(voter.vote))
            .reduce((prev, vote) => prev + vote, 0)
        setVoterAverage(sumOfAllVotes / votingVoters.length)
    }, [voters])

    useEffect(() => {
        // is average greater than/equal to each # in fibonacci
        // when false, take current & previous fibonacci #
        // subtract average from each, which diff is smaller
        const greaterThanIndex = fibonacci.findIndex(number => number >= voterAverage)
        const greaterDiff = Math.abs(voterAverage - fibonacci[greaterThanIndex])
        const lesserDiff = Math.abs(voterAverage - fibonacci[greaterThanIndex - 1])
        const theWinner = (greaterDiff < lesserDiff) ? fibonacci[greaterThanIndex] : fibonacci[greaterThanIndex - 1]
        setClosestEstimate(theWinner)
    }, [voterAverage])

    return (
        <Card sx={{ minWidth: 300, minHeight: '50%' }}>
            <CardHeader
                action={
                    <div>
                        {
                            member.level === 'admin' ?
                                <IconButton aria-label="settings" onClick={handleMenuClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                : null
                        }
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                onClick={toggleVotesAreDisplayed}
                            >
                                {/* <ListItemIcon>
                                    <HowToVote color="primary" fontSize="small" />
                                </ListItemIcon> */}
                                <ListItemIcon>
                                    <Visibility fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Reveal Votes
                                </ListItemText>
                                <Checkbox
                                    edge="end"
                                    onChange={toggleVotesAreDisplayed}
                                    checked={votesAreDisplayed}
                                />
                            </MenuItem>
                            <MenuItem
                                onClick={resetRoom}
                            >
                                {/* <ListItemIcon>
                                    <HowToVote color="primary" fontSize="small" />
                                </ListItemIcon> */}
                                <ListItemIcon>
                                    <RefreshIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    Reset Room
                                </ListItemText>
                            </MenuItem>

                        </Menu>
                    </div>
                }
                title="Vote Results"
                subheader={`${votersThatHaveVoted.length}/${voters.length} voters have voted`}
            />
            <CardContent>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1} columns={{ xs: 12 }}>
                            {voters
                                .map(voter => (
                                    <Grid key={voter.socketId} item>
                                        <VoteButton
                                            sx={{ height: 80, width: 60 }}
                                            style={{ background: voter.vote ? green : gray }}
                                        >
                                            <b>{votesAreDisplayed ? voter.vote : 'X'}</b>
                                        </VoteButton>
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className="card-actions">
                {
                    votesAreDisplayed ?
                        (<div style={{ marginLeft: 'auto' }}>
                            <span>
                                Average: {(voterAverage || 0).toFixed(3)}
                            </span>
                            <span style={{ paddingLeft: '15px' }}>Closest Estimate: {(closestEstimate || 0)}</span>
                        </div>) : null
                }


            </CardActions>
        </Card>
    )
}

export default VoterDisplay

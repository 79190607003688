import React from 'react'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CardHeader from '@mui/material/CardHeader'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import HowToVote from '@mui/icons-material/HowToVote'
import Checkbox from '@mui/material/Checkbox'
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings'
import Shield from '@mui/icons-material/GppGood'

import { styled } from '@mui/material/styles'
import { Tooltip } from '@mui/material'

const choices = ['0', '1/2', '1', '2', '3', '5', '8', '13', '21', '34', '100']
const gray = '#dedede'
const white = '#FFFFFF'

const VoteButton = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '80px',
}))

function DialPad({ member, isAVoterWasChanged, memberVoted, requestAdmin }) {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <Card sx={{ minWidth: 300, minHeight: '50%' }}>
            <CardHeader
                title="Cast Your Vote"
                subheader={member.isAVoter ? `Your vote is: ${member.vote || 'N/A'}` : 'You are not a voter'}
                action={
                    <div>
                        {
                            member.level === 'admin' ?
                                <Tooltip title="You are an admin of this room. You can reveal votes and reset the room from the menu on the Vote Results Card.">
                                    <IconButton>
                                        <Shield color="success" />
                                    </IconButton>
                                </Tooltip>
                                : null
                        }

                        <IconButton aria-label="settings" onClick={handleMenuClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                onClick={isAVoterWasChanged}
                            >
                                {/* <ListItemIcon>
                                    <HowToVote color="primary" fontSize="small" />
                                </ListItemIcon> */}
                                <ListItemIcon>
                                    <HowToVote fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    I am a voter
                                </ListItemText>
                                <Checkbox
                                    edge="end"
                                    onChange={isAVoterWasChanged}
                                    checked={member.isAVoter || false}
                                />
                            </MenuItem>
                            {
                                member.level !== 'admin' ?
                                    <Tooltip title="Request to be an admin of the room. Admins can reveal votes and reset the room.">
                                        <MenuItem
                                            onClick={requestAdmin}
                                        >
                                            <ListItemIcon>
                                                <AdminPanelSettings fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>
                                                Request Admin Permission
                                            </ListItemText>
                                        </MenuItem>
                                    </Tooltip>
                                    : null
                            }

                        </Menu>
                    </div>
                }
            />
            <CardContent>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1} columns={{ xs: 12 }}>
                            {choices.map((value) => (
                                <Grid key={value} item>
                                    <Button
                                        onClick={() => { memberVoted(value) }}
                                        disabled={!member.isAVoter}
                                    >
                                        <VoteButton style={{ background: member.isAVoter ? white : gray }} sx={{ height: 80, width: 60 }} >
                                            <b>{value}</b>

                                        </VoteButton>
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>

            </CardActions>
        </Card>
    )
}

export default DialPad

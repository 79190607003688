import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Entry from './components/Entry'
import Room from './components/Room'
import NotFound from './components/Errors/NotFound'
import PasswordProtected from './components/Errors/PasswordProtected'

// custom font imports
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Entry />} />
        <Route path="/home" element={<Entry />} />
        <Route path="/home/:mode" element={<Entry />} />
        <Route path="/home" element={<Entry />} />
        <Route path="/room/:roomCode" element={<Room />} />
        <Route path='/404' element={<NotFound/>} />
        <Route path='/password-protected' element={<PasswordProtected/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import React from 'react'
import { useState, useEffect, forwardRef } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import CastleIcon from '@mui/icons-material/Castle'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import webSocketService from '../services/websocket-service'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { getConfig } from '../services/config'

// import { Button, Stack, TextField } from '@mui/material/'
import { useNavigate, useParams } from 'react-router-dom'
import './entry.css'

const config = getConfig(process.env.REACT_APP_ENVIRONMENT)

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <span>Casey Ferguson &nbsp;</span>
            {/* <a color="inherit" href="https://caseyferguson.dev/">
                Casey Ferguson
            </a>{' '} */}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const theme = createTheme({
    palette: {
        secondary: {
            main: '#4db6ac',
            contrastText: 'white'
        }
    }
})

function Entry() {
    // // Object destructuring
    // const obj = { c: 'c', a: 'a', b: 'b' }
    // const { a: anotherA, b } = obj
    // const anotherA = obj.a
    // const b = obj.b

    // // Array destructuring
    // const arr = ['a', 'b', 'c']
    // const [anotherA, b] = arr
    // const anotherA = arr[0]
    // const b = arr[1]
    // console.log(a) // -> 'a'
    // console.log(b) // -> 'b'
    const BASE_URL = `${config.baseAPIUrl}/api/v1`
    const roomCodeDescriptions = [
        'Room Code (Leave blank for random code)',
        'Room Code (Required)'
    ]
    const buttonTextDescriptions = [
        'Create',
        'Join'
    ]
    const colorThemes = [
        'primary',
        'secondary'
    ]

    const { mode } = useParams()

    const modes = {
        'create': 0,
        'join': 1
    }

    const [roomCode, setRoomCode] = useState('')
    const [password, setPassword] = useState('')
    const [isVoter, setIsVoter] = useState(true)
    const [roomCodeError, setRoomCodeError] = useState({
        isError: false,
        errorText: ''
    })
    const [passwordError, setPasswordError] = useState({
        isError: false,
        errorText: ''
    })
    const [errorMessage, setErrorMessage] = useState('')
    const [snackBarIsOpen, setSnackBarIsOpen] = useState(false)
    const [socket] = useState(webSocketService())
    const [activeTab, setActiveTab] = useState(0)
    const [roomCodeDescription, setRoomCodeDescription] = useState(roomCodeDescriptions[0])
    const [buttonText, setButtonText] = useState(buttonTextDescriptions[0])
    const [colorTheme, setColorTheme] = useState(colorThemes[0])

    const navigate = useNavigate()

    useEffect(() => {
        if (mode && modes[mode.toLowerCase()]) {
            handleTabClicked(null, modes[mode.toLowerCase()])
        }
        socket.removeAllListeners()
        socket.on('connect', () => {
            console.log('socket is connected')
        })
        socket.on('error', err => {
            console.log('error', err)
            let msg
            if (err.code === 'room-not-found') msg = err.message
            showError(msg)
        })
        socket.on('room-updated', data => {
            console.log('data', data)
        })
        socket.on('join-successful', room => {
            console.log('You have successfully joined the room.', room)
            navigate(`/room/${room.code}`)
        })
    }, [])

    const showError = (message = 'Sorry, something went wrong. Please try again later.') => {
        setErrorMessage(message)
        setSnackBarIsOpen(true)
    }

    const joinRoom = (roomCode) => {
        socket.emit('join', {
            roomCode: roomCode,
            password: password
        })
    }

    const handleJoinClicked = event => {
        event.preventDefault()
        const fieldsAreValid = validateInputs([
            { str: roomCode, setFunc: setRoomCodeError, fieldDesc: 'Room Codes', required: true },
            { str: password, setFunc: setPasswordError, fieldDesc: 'Passwords', required: false }
        ])
        if (fieldsAreValid) {
            joinRoom(roomCode)
        }
        console.log('we joined')
    }

    const handleCreateClicked = async (event) => {
        event.preventDefault()
        const fieldsAreValid = validateInputs([
            { str: roomCode, setFunc: setRoomCodeError, fieldDesc: 'Room Codes', required: false },
            { str: password, setFunc: setPasswordError, fieldDesc: 'Passwords', required: false }
        ])
        console.log('fields were all valid', fieldsAreValid)

        if (fieldsAreValid) {
            const payload = {}
            if (roomCode) payload.code = roomCode
            if (password) payload.password = password

            try {
                const { data: newRoom } = await axios.post(`${BASE_URL}/room`, payload)
                console.log('response', newRoom)
                joinRoom(newRoom.code)
            } catch (error) {
                // if (error.response) {
                //     message = 'Some Specific Message'
                // }
                showError()
            }

            // TODO:
            // Navigate to Room page (join the room as the creator)
        }
        console.log('handle submit is happening')

        // eslint-disable-next-line no-console
    }

    const validateInputs = (inputConfigs) => {
        // for (let i = 0; i < inputConfigs.length; i++) {
        //     const inputConfig = inputConfigs[i]
        // }
        // <------------ SAME THING -------------->
        // for (const inputConfig of inputConfigs) {

        // } 
        let allConfigsAreValid = true
        for (const inputConfig of inputConfigs) {
            if (!inputIsValid(inputConfig.str, inputConfig.required)) {
                allConfigsAreValid = false
                inputConfig.setFunc({
                    isError: true,
                    errorText: `${inputConfig.fieldDesc} must be between 6 and 50 characters.`
                })
            } else {
                inputConfig.setFunc({
                    isError: false,
                    errorText: ''
                })
            }
        }
        return allConfigsAreValid
    }

    const inputIsValid = (str, required) => {
        return (!required && str === '') || (str.length <= 50 && str.length >= 6)
    }

    const handleRoomCodeChange = event => {
        setRoomCode(event.target.value)
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value)
    }

    const handleIsVoterChange = event => {
        setIsVoter(event.target.checked)
    }

    const handleClose = () => {
        setSnackBarIsOpen(false)
    }

    const handleTabClicked = (event, newTabIndex) => {
        setActiveTab(newTabIndex)
        setRoomCodeDescription(roomCodeDescriptions[newTabIndex])
        setButtonText(buttonTextDescriptions[newTabIndex])
        setColorTheme(colorThemes[newTabIndex])
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random?collection=97575324)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: `${colorTheme}.main` }}>
                            {activeTab === 0 ? <SmartToyIcon /> : <CastleIcon />}
                        </Avatar>

                        <Typography className='entry-title' component="h1" variant="h5">
                            scrummi
                        </Typography>

                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={activeTab}
                                    onChange={handleTabClicked}
                                    aria-label="basic tabs example"
                                    textColor={colorTheme}
                                    indicatorColor={colorTheme}
                                >
                                    <Tab label="Create a room" {...a11yProps(0)} />
                                    <Tab label="Join a room" {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            <TextField
                                color={colorTheme}
                                error={roomCodeError.isError}
                                helperText={roomCodeError.errorText}
                                margin="normal"
                                fullWidth
                                id="roomCode"
                                label={roomCodeDescription}
                                name="roomCode"
                                value={roomCode}
                                onChange={handleRoomCodeChange}
                                autoFocus
                            />
                            <TextField
                                color={colorTheme}

                                error={passwordError.isError}
                                helperText={passwordError.errorText}
                                margin="normal"
                                fullWidth
                                name="password"
                                label="Password (optional)"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="current-password"
                            />
                            <FormControlLabel
                                control=
                                {
                                    <Checkbox
                                        value="remember"
                                        color={colorTheme}
                                        checked={isVoter}
                                        onChange={handleIsVoterChange}
                                    />
                                }
                                label="Make me a voter"

                            />
                            <Button
                                color={colorTheme}
                                type="submit"
                                size="large"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={activeTab === 0 ? handleCreateClicked : handleJoinClicked}
                            >
                                {buttonText}
                            </Button>
                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Snackbar
                open={snackBarIsOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
        // <div>
        //     <Stack spacing={2}>
        //         <Button to={'/room'} component={Link}>Create new room</Button>
        //         <h3 style={{ textAlign: 'center' }}>Join an existing room:</h3>
        //         {/* <Button to={'/room'} component={Link}>Join an existing room</Button> */}
        //         <Stack className='newRoom' style={{textAlign: 'center'}} direction='row'>
        //             <TextField style={{ textAlign: 'center', maxWidth: 300 }} label='room number'></TextField>
        //             <Button>Go</Button>
        //         </Stack>
        //     </Stack>



        // </div>
    )
}

export default Entry

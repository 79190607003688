import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import InputUnstyled from '@mui/base/InputUnstyled'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
// import NotificationIcon from '@mui/icons-material/Notifications'
// import Circle from '@mui/icons-material/Circle'
// import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'
// import ListItemText from '@mui/material/ListItemText'
// import ListItemIcon from '@mui/material/ListItemIcon'

const gray = 'rgb(45, 56, 67, 0.5)'
const lightGray = 'rgb(78, 98, 120, 0.8)'
const darkGray = 'rgb(61, 76, 92, 0.8)'

const blue = {
    200: '#80BFFF',
    400: '#3399FF',
}

const grey = {
    50: '#F3F6F9',
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
}

const StyledInputElement = styled('input')(
    ({ theme }) => `
    width: 100%;
    height: 40px;
    font-size: 0.875rem;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
    border-radius: 8px;
    padding: 12px 12px;
    transition: all 150ms ease;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
  
    &:focus {
      outline: 2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      outline-offset: 2px;
    }
  `,
)

const CustomInput = React.forwardRef(function CustomInput(props, ref) {
    return (
        <InputUnstyled components={{ Input: StyledInputElement }} {...props} ref={ref} />
    )
})

export default function Nav({ roomCode = '', ticketLink = '', updateTicket }) {
    const [isHoveringOverTicketLink, setIsHoveringOverTicketLink] = useState(false)
    const [isEditingTicketLink, setIsEditingTicketLink] = useState(false)
    const [tmpTicketLink, setTmpTicketLink] = useState(ticketLink)
    const [anchorEl, setAnchorEl] = useState(null)


    useEffect(() => {
        setTmpTicketLink(ticketLink)
    }, [ticketLink])

    const handleMouseOver = () => {
        setIsHoveringOverTicketLink(true)
    }

    const handleMouseExit = () => {
        setIsHoveringOverTicketLink(false)
    }

    const handleEditTicketLinkClicked = () => {
        setIsEditingTicketLink(true)
    }

    const handleTicketLinkClicked = () => {
        if (ticketLink) {
            window.open(ticketLink, '_blank')
        }
    }

    const handleTicketLinkInputBlurred = event => {
        setIsEditingTicketLink(false)
        setIsHoveringOverTicketLink(false)
        // update the ticket link
        console.log('updating ticket')
        updateTicket(event.target.value)
    }

    const handleTicketLinkInputChanged = event => {
        setTmpTicketLink(event.target.value)
    }

    // const handleNotifcationMenuClick = (event) => {
    //     setAnchorEl(event.currentTarget)
    // }
    const handleNotifcationMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: gray }}>
                <Toolbar>
                    <Grid justify={"space-between"} container style={{ marginTop: '10px' }}>
                        <Grid xs={2} item>
                            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                                <Link to='/' className='nav-heading' style={{ textDecoration: 'none' }}>scrummi</Link>
                            </Typography>
                        </Grid>
                        <Grid xs={8} item>
                            <Grid container justify={"center"} style={{ paddingTop: '14px', textAlign: 'center' }}>
                                <Grid xs={4} item />
                                <Grid xs={4} item>
                                    {isEditingTicketLink
                                        ?
                                        <CustomInput
                                            placeholder="Enter a Ticket Link"
                                            value={tmpTicketLink}
                                            autoFocus
                                            onChange={handleTicketLinkInputChanged}
                                            onBlur={handleTicketLinkInputBlurred}
                                        />
                                        :
                                        <Grid container jusitfy={"center"}>
                                            <Grid item xs={11}>
                                                <Tooltip title="Click to open the ticket link in a new tab.">

                                                    <Typography
                                                        variant="p"
                                                        component="div"
                                                        sx={{ flexGrow: 1 }}
                                                        style={{
                                                            background: isHoveringOverTicketLink ? darkGray : lightGray,
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                            height: '40px',
                                                            lineHeight: '40px',
                                                            textAlign: 'center',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            paddingLeft: '10px',
                                                            paddingRight: '10px'
                                                        }}
                                                        onMouseEnter={ticketLink ? handleMouseOver : () => undefined}
                                                        onMouseLeave={handleMouseExit}
                                                        onClick={handleTicketLinkClicked}
                                                    >
                                                        {ticketLink || 'Enter a Ticket Link...'}

                                                    </Typography>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title="Change the Ticket Link">
                                                    <IconButton aria-label="settings" onClick={handleEditTicketLinkClicked}>
                                                        <EditIcon style={{ color: 'white' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>
                                <Grid xs={4} item />
                            </Grid>
                        </Grid>

                        <Grid item xs={2}>
                            <h3 style={{ textAlign: 'right', marginTop: '20px' }}>
                                <span style={{ paddingRight: '5px' }}>
                                    {/* <IconButton id="notification-button" aria-label="settings" onClick={handleNotifcationMenuClick}>
                                        <Badge badgeContent={1} color="error">
                                            <NotificationIcon style={{ color: 'white', fontSize: '1.2em' }} />
                                        </Badge>
                                    </IconButton> */}
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleNotifcationMenuClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        {/* <MenuItem onClick={handleNotifcationMenuClose}>
                                            <ListItemIcon>
                                                <Circle color="primary" fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText>Doug has requested admin Access</ListItemText>
                                        </MenuItem> */}

                                    </Menu>
                                </span>
                                &nbsp;Room: {roomCode}
                            </h3>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {/* <Box item sm={12} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '-20px'  }}>
                    <Typography variant="h4">10:00</Typography>
            </Box> */}
        </Box>
    )
}
import React from 'react'
import { useState, useEffect, forwardRef } from 'react'
import { Button, Grid } from '@mui/material'
import './room.css'
import VoterDisplay from './VoterDisplay'
import DialPad from './DialPad'
import CssBaseline from '@mui/material/CssBaseline'
import Nav from './Nav'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import webSocketService from '../services/websocket-service'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { getConfig } from '../services/config'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

const config = getConfig(process.env.REACT_APP_ENVIRONMENT)

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const theme = createTheme()
const BASE_URL = `${config.baseAPIUrl}/api/v1`

function Room() {
    const { roomCode } = useParams()

    const [room, setRoom] = useState({})
    const [socket] = useState(webSocketService())
    const [matchingMember, setMatchingMember] = useState({})

    const [errorSnackBarIsOpen, setErrorSnackBarIsOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [infoSnackBarIsOpen, setInfoSnackBarIsOpen] = useState(false)
    const [infoMessage, setInfoMessage] = useState('')

    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [userName, setUserName] = useState('')
    const [pendingApprovalSocketId, setPendingApprovalSocketId] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        socket.removeAllListeners()
        socket.on('connect', () => {
            console.log('socket is connected', socket)
        })
        socket.on('error', err => {
            console.log('error', err)
            let msg
            if (err.code === 'room-not-found') msg = err.message
            if (err.code === 'joi-validation-error') {
                if (err.details?.some(errDetails => errDetails.field === 'ticketLink')) {
                    msg = 'Ticket link update failed. Please make sure you have entered a valid URL'
                }
            }
            showError(msg)
        })
        socket.on('room-updated', updatedRoom => {
            console.log('room-updated', updatedRoom)
            setRoom(updatedRoom)
            const newMatchingMember = updatedRoom.members.find(m => m.socketId === socket.id)
            if (newMatchingMember.socketId !== matchingMember.socketId) {
                setMatchingMember(newMatchingMember)
            }
        })
        socket.on('join-successful', () => {
            console.log('we joined it!')
        })
        socket.on('admin-requested', (payload) => {
            setInfoMessage(`${payload.userName} is requesting admin access. Please confirm or deny.`)
            setInfoSnackBarIsOpen(true)
            setPendingApprovalSocketId(payload.socketId)
        })

        const initialFetch = async () => {
            try {
                const { data: theRoom } = await axios.get(`${BASE_URL}/room/${roomCode}`)
                setRoom(theRoom)

                const theMatchingMember = theRoom.members.find(m => m.socketId === socket.id)

                if (!theMatchingMember) {
                    if (theRoom.isPasswordProtected) {
                        console.log('the room is password protected... join it')
                        navigate('/password-protected')
                    } else {
                        console.log('joining room...')
                        console.log('getting from local stoage', `admin-id-room-${theRoom._id}`)
                        const previousMemberId = localStorage.getItem(`admin-id-room-${theRoom._id}`)
                        console.log('we found the previous member id', previousMemberId)
                        socket.emit('join', {
                            roomCode: roomCode,
                            previousMemberId
                        })
                    }
                } else {
                    setMatchingMember(theMatchingMember)
                }
            } catch (error) {
                console.log('response is', error.response)
                if (error.response.status === 404) {
                    navigate('/404')
                }
            }
        }
        initialFetch()
    }, [])

    useEffect(() => {
        console.log('matching member changed', matchingMember, room)
        if (room._id && matchingMember.level === 'admin') {
            console.log('setting local storage')
            localStorage.setItem(`admin-id-room-${room._id}`, matchingMember._id)
        }
    }, [matchingMember])

    const showError = (message = 'Sorry, something went wrong. Please try again later.') => {
        setErrorMessage(message)
        setErrorSnackBarIsOpen(true)
    }

    const handleIsAVoterToggled = () => {
        socket.emit('toggle-voter', {
            roomId: room._id
        })
    }

    const handleMemberVoted = (memberVote) => {
        console.log('voting', memberVote)
        socket.emit('vote', {
            roomId: room._id,
            vote: memberVote
        })
    }

    const handleTicketIsUpdated = (ticketLink) => {
        socket.emit('update-ticket-link', {
            roomId: room._id,
            ticketLink
        })
    }

    const handleErrorSnackbarClose = () => {
        setErrorSnackBarIsOpen(false)
    }

    const handleInfoSnackbarClose = () => {
        setInfoSnackBarIsOpen(false)
    }

    const handleRoomReset = () => {
        socket.emit('reset-room', {
            roomId: room._id
        })
    }

    const handleToggleVotesAreDisplayed = () => {
        socket.emit('toggle-display-votes', {
            roomId: room._id
        })
    }

    const handleAdminRequestClicked = () => {
        setModalIsOpen(true)
    }

    const handleAdminRequestSubmitClicked = () => {
        socket.emit('request-admin-permission', {
            roomId: room._id,
            userName
        })
        setModalIsOpen(false)
    }

    const handleAdminApproveClicked = () => {
        socket.emit('approve-admin-request', {
            roomId: room._id,
            memberSocketId: pendingApprovalSocketId
        })
        setInfoSnackBarIsOpen(false)
    }

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{
                height: '100vh',
                backgroundImage: 'url(https://source.unsplash.com/random?collection=2281806)',
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }} >
                <Nav
                    roomCode={room.code}
                    ticketLink={room.ticketLink}
                    updateTicket={handleTicketIsUpdated}
                />

                <Grid container spacing={4} rowSpacing={1} component="main" sx={{ minHeight: '80%', paddingLeft: '10%', paddingRight: '10%' }}>
                    <CssBaseline />

                    <Grid
                        item
                        sm={6}
                    >
                        <DialPad
                            isAVoterWasChanged={handleIsAVoterToggled}
                            memberVoted={handleMemberVoted}
                            member={matchingMember}
                            requestAdmin={handleAdminRequestClicked}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={6}
                    >
                        <VoterDisplay
                            members={room.members}
                            resetRoom={handleRoomReset}
                            member={matchingMember}
                            votesAreDisplayed={room.votesAreDisplayed}
                            toggleVotesAreDisplayed={handleToggleVotesAreDisplayed}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Card sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: '10px'
                }}>
                    <CardHeader
                        // action={
                        //     <Tooltip title="Reset the Room">
                        //         <IconButton aria-label="settings">
                        //             <RefreshIcon />
                        //         </IconButton>
                        //     </Tooltip>
                        // }
                        title="Admin Request"
                        subheader="Please enter your name for approval. Other admins in the room will recieve the request under this name."
                    ></CardHeader>
                    <CardContent>
                        <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            defaultValue=""
                            style={{ width: '100%' }}
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleAdminRequestSubmitClicked} variant="contained" size="large">Submit</Button>
                    </CardActions>
                </Card>
            </Modal>
            <Snackbar
                open={errorSnackBarIsOpen}
                autoHideDuration={6000}
                onClose={handleErrorSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Alert onClose={handleErrorSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Snackbar
                open={infoSnackBarIsOpen}
                autoHideDuration={60000}
                onClose={handleInfoSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Alert onClose={handleInfoSnackbarClose} severity="primary" sx={{ width: '100%' }}>
                    <div>{infoMessage}</div>
                    <Button onClick={handleAdminApproveClicked} style={{ margin: '10px'}} color="info" variant="contained">Confirm</Button>
                    <Button onClick={handleInfoSnackbarClose} color="info" variant="contained">Deny</Button>
                </Alert>
            </Snackbar>
        </ThemeProvider >
    )
}

export default Room
